import { EntityId } from "@jackfruit/common"
import { SagaIterator } from "redux-saga"
import { select } from "redux-saga/effects"
import { ProductPageEntity } from "~/interfaces/entities/ProductPage"
import { productPagesSelectors } from "../state/productPages"
import { RootState } from "../store"

export function* getProductPage(
  productPageId: EntityId
): SagaIterator<ProductPageEntity> {
  const productPage: ProductPageEntity = yield select((state: RootState) =>
    productPagesSelectors.selectById(state, productPageId)
  )

  return productPage
}
