import { EntityId, PrinticularOrder } from "@jackfruit/common"
import { StoreEntity } from "~/interfaces/entities/Store"

interface GtmProduct {
  id: string
  name: string
  variant: string
  price: number
  quantity: number
  brand: string
}

// Helper functions for google tag manager
export class Gtm {
  /** GTM: Push data to data layer */
  public static push(data: any) {
    if (!window.dataLayerUA) {
      console.error("GTM is not configured correctly, please check dataLayerUA")
    }
    window.dataLayerUA && window.dataLayerUA.push(data)
  }

  /** GTM values can only accepts integers */
  private static toCents(dollar: number) {
    return dollar * 100
  }

  public static fireApplyCouponEvent(
    storeName: string | undefined,
    totalDiscount: number,
    orderValue: number,
    couponCode: string
  ) {
    Gtm.push({
      event: "Apply coupon",
      fulfillment: storeName ? storeName : "Delivery",
      totalDiscount: Gtm.toCents(totalDiscount),
      orderValue: Gtm.toCents(orderValue),
      couponCode: couponCode,
    })
  }

  public static fireInvalidCouponEvent(
    storeName: string | undefined,
    orderValue: number,
    couponCode: string
  ) {
    Gtm.push({
      event: "Invalid coupon",
      fulfillment: storeName ? storeName : "Delivery",
      orderValue: Gtm.toCents(orderValue),
      couponCode: couponCode,
    })
  }

  private static prepareProductListForGtm(
    order: PrinticularOrder,
    store: StoreEntity | null
  ) {
    const gtmProductsDict: { [productKey: string]: GtmProduct } = {}

    for (let lineItem of order.lineItems) {
      const productKey = lineItem.product.id
      if (!gtmProductsDict[productKey]) {
        const newGtmProduct = {
          id: lineItem.product.id.toString(),
          name: lineItem.product.name,
          variant: "none", // ignoring variant atm as it was not needed
          price: lineItem.unitPriceFloat,
          quantity: 0,
          brand: store ? store.retailerId : "Delivery",
        }

        gtmProductsDict[productKey] = newGtmProduct
      }
      gtmProductsDict[productKey].quantity += lineItem.quantity
    }

    return gtmProductsDict
  }

  public static firePurchaseEvent(
    order: PrinticularOrder,
    store: StoreEntity | null
  ) {
    const gtmProductsDict = Gtm.prepareProductListForGtm(order, store)
    const productIdsAndQuantity = order.lineItems
      .filter(lineItem => lineItem.product.id && lineItem.quantity)
      .map(lineItem => ({
        id: lineItem.product.id,
        quantity: lineItem.quantity,
      }))

    Gtm.push({
      event: "purchase",
      ecommerce: {
        currencyCode: order.currency,
        purchase: {
          actionField: {
            id: order.id.toString(),
            revenue: order.total,
            affiliation: store ? store.name : "Delivery",
            tax: order.taxTotal,
            shipping: order.freight,
            coupon: order.couponCode,
          },
          facebook: {
            content_type: "product",
            contents: productIdsAndQuantity,
          },
          products: Object.values(gtmProductsDict),
        },
      },
    })
  }

  public static fireSubmitOrderEvent(
    order: PrinticularOrder,
    store: StoreEntity | null
  ) {
    Gtm.push({
      event: "Submit order",
      fulfillment: store ? store.name : "Delivery",
      orderId: order.id,
      orderValue: Gtm.toCents(order.totalFloat),
    })
  }

  public static firePaymentFailedEvent(
    errorMessage: string,
    store: StoreEntity | null,
    orderValue: number
  ) {
    Gtm.push({
      event: "Payment failed",
      errorMessage: errorMessage,
      fulfillment: store ? store.name : "Delivery",
      orderValue: Gtm.toCents(orderValue),
    })
  }

  public static fireSubmitOrderFailedEvent(
    errorMessage: string,
    store: StoreEntity | null,
    orderValue: number
  ) {
    Gtm.push({
      event: "Submit order failed",
      errorMessage: errorMessage,
      fulfillment: store ? store.name : "Delivery",
      orderValue: Gtm.toCents(orderValue),
    })
  }

  public static fireStoreSearchEvent(numOfStoresFound: number) {
    Gtm.push({
      event: "Store search",
      storesFound: numOfStoresFound,
    })
  }

  public static fireFileUploadEvent(numOfFilesAdded: number) {
    Gtm.push({
      event: "Add files",
      count: numOfFilesAdded,
    })
  }

  // Use to make GTM v1 backward compatable
  // We have to fire our own click event instead of the GTM auto ones
  public static fireCustomClickEvent(elementClass: string, elementId: string) {
    Gtm.push({
      event: "gtm.click",
      "gtm.elementClasses": elementClass,
      "gtm.elementId": elementId,
    })
  }

  public static fireCustomLinkClickEvent(
    elementClass: string,
    elementId: string
  ) {
    Gtm.push({
      event: "gtm.linkClick",
      "gtm.elementClasses": elementClass,
      "gtm.elementId": elementId,
    })
  }

  public static fireAbTestAttribute(defaultAbTestAttribute: string): string {
    const abCookieAttribute = document.cookie.match(/X-Source=(.*?)(;|$)/)?.[1]
    const abTest = abCookieAttribute ?? defaultAbTestAttribute

    Gtm.push({
      abTest,
    })

    return abTest
  }
  public static fireTemplateDrawerOpenEvent() {
    Gtm.push({
      event: "Template drawer open",
    })
  }

  public static fireTemplateSearchEvent(searchTerm: string) {
    Gtm.push({
      event: "Template search",
      searchTerm: searchTerm,
    })
  }

  public static fireTemplateCheckboxAndSearchEvent(
    templateSlug: EntityId[] | EntityId,
    searchTerm: string
  ) {
    Gtm.push({
      event: "Template checkbox",
      templateSlug: templateSlug,
      searchTerm: searchTerm,
    })
  }
}
