import Joi from "joi"

export const deliveryOrderBaseValidationSchema = Joi.object({
  fulfillment: Joi.string().required(),
  lineItems: Joi.array()
    .items(
      Joi.object({
        isReady: Joi.boolean().valid(true),
      })
    )
    .min(1),
  printService: Joi.object().required(),
  printServiceProducts: Joi.array().min(1),
  store: Joi.object().allow(null),
  user: Joi.object({}),
})

export const deliveryOrderAddressValidationSchema = Joi.object({
  address: Joi.object({
    // name: Joi.string().min(2).required(),
    line1: Joi.string().min(2).required(),
    line2: Joi.string().allow(""),
    city: Joi.string().min(2).required(),
    country: Joi.string().min(2).required(),
    postcode: Joi.string().min(2).required(),
  }),
})

export const deliveryOrderPaymentValidationSchema = Joi.object({
  payment: Joi.object().required(),
})

export const pickupOrderValidationSchema = Joi.object({
  fulfillment: Joi.string().required(),
  store: Joi.object().required(),
  payment: Joi.object().required(),
  address: Joi.object({
    // name: Joi.string().min(2).required(),
  }),
  lineItems: Joi.array()
    .items(
      Joi.object({
        isReady: Joi.boolean().valid(true),
      })
    )
    .min(1),
  // images: Joi.array().min(1),
  // uploads: Joi.array().min(1),
  printService: Joi.object().required(),
  printServiceProducts: Joi.array().min(1),
})
