import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { Address } from "~/interfaces/entities/Address"
import { User } from "~/interfaces/entities/User"

export interface CartState {
  user: User
  address: Address
}

const initialState: CartState = {
  user: {
    fullName: "",
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
  },
  address: {
    name: "",
    line1: "",
    line2: "",
    city: "",
    state: "",
    country: "",
    postcode: "",
  },
}

export const cart = createSlice({
  name: "cart",
  initialState,
  reducers: {
    // USER
    updateUser(state: CartState, action: PayloadAction<User>) {
      state.user = action.payload
    },
    // ADDRESS
    updateAddress(state: CartState, action: PayloadAction<Address>) {
      state.address = action.payload
    },
  },
})
