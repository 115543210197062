import { EntityId } from "@jackfruit/common"
import { atom, atomFamily } from "recoil"
import { TextRegionEntity } from "~/interfaces/entities/TextRegion"
import { Point } from "~/services/cropHelpers"

// Page Selection
export const regionActivation = atomFamily<
  boolean,
  { type?: "image" | "text"; id: EntityId }
>({
  key: "regionActivation",
  default: false,
})

export const selectedRegion = atom<{ type?: "image" | "text"; id: EntityId }>({
  key: "selectedRegion",
  default: { id: 0 },
})

// Editor scrolling position
export const editorScrollPosition = atom({
  key: "editorScrollPosition",
  default: 0,
})

// Product clamping
export const editorCurrentProductId = atom<EntityId>({
  key: "editorCurrentProductId",
  default: -1,
})

// Image Transformations
export const imageTransformationZoomStateFamily = atomFamily<number, EntityId>({
  key: "imageTransformationZoom",
  default: 1,
})

export const imageTransformationRotationStateFamily = atomFamily<
  number,
  EntityId
>({
  key: "imageTransformationRotation",
  default: 0,
})

export const imageTransformationTranslationStateFamily = atomFamily<
  Point,
  EntityId
>({
  key: "imageTransformationTranslation",
  default: { x: 0, y: 0 },
})

export const imageTransformationMinZoomStateFamily = atomFamily<
  number,
  EntityId
>({
  key: "imageTransformationMinZoom",
  default: 0,
})

export const textRegionAlignFamily = atomFamily<
  TextRegionEntity["align"],
  EntityId
>({
  key: "textRegionAlign",
  default: "left",
})

export const textRegionColorFamily = atomFamily<
  TextRegionEntity["color"],
  EntityId
>({
  key: "textRegionColor",
  default: "",
})

export const textRegionFontFamily = atomFamily<
  TextRegionEntity["font"],
  EntityId
>({
  key: "textRegionFont",
  default: "",
})

export const textRegionKeyFamily = atomFamily<
  TextRegionEntity["key"],
  EntityId
>({
  key: "textRegionKey",
  default: "",
})

export const textRegionTextFamily = atomFamily<
  TextRegionEntity["text"],
  EntityId
>({
  key: "textRegionText",
  default: "",
})

export const textRegionSizeFamily = atomFamily<
  TextRegionEntity["size"],
  EntityId
>({
  key: "textRegionSize",
  default: 0.5,
})

export const isMultiImageRegionHovered = atomFamily<boolean, EntityId>({
  key: "isMultiImageRegionHovered",
  default: false,
})

export const photoBookPageIdsFamily = atomFamily<EntityId[], EntityId>({
  key: "photoBookPageIds",
  default: [],
})
