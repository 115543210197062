import * as React from "react"
import { useEffect } from "react"

var syncFallback = function syncFallback(create) {
  return create()
}

var useInsertionEffect = React["useInsertion" + "Effect"]
  ? React["useInsertion" + "Effect"]
  : false
var useInsertionEffectAlwaysWithSyncFallback =
  useInsertionEffect || syncFallback
var useInsertionEffectWithLayoutFallback = useInsertionEffect || useEffect

export {
  useInsertionEffectAlwaysWithSyncFallback,
  useInsertionEffectWithLayoutFallback,
}
