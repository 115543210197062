import { PayloadAction } from "@reduxjs/toolkit"
import { SagaIterator } from "redux-saga"
import { getContext, put, select, takeEvery } from "redux-saga/effects"
import { CartEntity } from "~/interfaces/entities/Cart"
import { actions, OrderFromStorePayload } from "../process"
import { carts, cartsSelector } from "../state/carts"
import { RootState } from "../store"

/**
 * Triggered when user click on `Order From This Store` from a store page
 */
export default function* watchOrderFromStore() {
  yield takeEvery(actions.orderFromStore.type, processOrderFromStore)
}

function* processOrderFromStore(
  action: PayloadAction<OrderFromStorePayload>
): SagaIterator {
  const navigate = yield getContext("navigate")
  const { upsertMany: upsertManyCarts } = carts.actions

  const allCarts: CartEntity[] = yield select((state: RootState) =>
    cartsSelector.selectAll(state)
  )

  // Select all carts that don't have any line items
  const blankCarts = allCarts.filter(c => c.lineItemIds.length === 0)

  if (blankCarts.length > 0) {
    const cartsPayload = blankCarts.map(cart => ({
      id: cart.id,
      ...action.payload,
    }))

    yield put(upsertManyCarts(cartsPayload))
  }

  navigate("/")
}
