import { AppEntityHydrated, BlockFooterEntityHydrated } from "@jackfruit/common"
import React from "react"
import "./FooterBlock.css"
import FooterBlockA from "./FooterBlockA"

interface Props {
  appConfig: AppEntityHydrated
  config: BlockFooterEntityHydrated
}

/*
 * *  Hide GoogleOptimize for now due to odd behavior
 */

// const FooterBlockWithOptimize: React.FC<Props> = ({ appConfig, config }) => {
//   const {
//     settings: { googleOptimizeExperimentIdOne },
//   } = appConfig
//   const FooterBlockVariant = useGoogleOptimize(googleOptimizeExperimentIdOne, [
//     FooterBlockA,
//     FooterBlockB,
//   ])

//   return !FooterBlockVariant ? (
//     <FooterBlockA appConfig={appConfig} config={config} />
//   ) : (
//     <FooterBlockVariant appConfig={appConfig} config={config} />
//   )
// }

const FooterBlock: React.FC<Props> = ({ appConfig, config }) => {
  // const {
  //   settings: { googleOptimizeExperimentIdOne },
  // } = appConfig

  // return googleOptimizeExperimentIdOne ? (
  //   <FooterBlockWithOptimize appConfig={appConfig} config={config} />
  // ) : (
  //   <FooterBlockA appConfig={appConfig} config={config} />
  // )
  return <FooterBlockA appConfig={appConfig} config={config} />
}

export default FooterBlock
