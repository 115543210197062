import { EntityId, FulfillmentTypes, PageEntity } from "@jackfruit/common"
import { call, put, select, takeEvery } from "@redux-saga/core/effects"
import { SagaIterator } from "@redux-saga/types"
import md5 from "md5"
import { Address } from "~/interfaces/entities/Address"
import { CartEntity } from "~/interfaces/entities/Cart"
import { LocalStorageKeys } from "~/interfaces/entities/LocalStorageKeys"
import { PageSessionEntity } from "~/interfaces/entities/PageSession"
import { User } from "~/interfaces/entities/User"
import { getLocalStorageItem, setLocalStorageItem } from "~/services/Utils"
import { actions } from "../process"
import { ApplicationState } from "../state/application"
import { AuthState } from "../state/auth"
import { cart, CartState } from "../state/cart"
import { carts, cartsSelector } from "../state/carts"
import { pageSessionsSelector } from "../state/pageSessions"
import { RootState } from "../store"
import { getApplication } from "./application"
import { getCart } from "./cart"
import { getAllPAges } from "./page"

export function* watchRestoreCheckoutDataFromLocalStorage() {
  yield takeEvery(
    actions.restoreCheckoutData.type,
    restoreCheckoutDataFromLocalStorage
  )
}

export function* watchRestoreCartDataFromLocalStorage() {
  yield takeEvery(actions.restoreCartData.type, restoreCartDataFromLocalStorage)
}

function* getLocalStorageKeys(): SagaIterator<LocalStorageKeys> {
  const application: ApplicationState = yield call(getApplication)

  let userPrefix = ""
  const authState: AuthState = yield select((state: RootState) => state.auth)
  if (authState.isAuthenticated) {
    userPrefix = `${md5(authState.emailAddress)}_`
  }

  const localStorageAppBannerKey = application.localStorageAppBannerKey
  const localStorageCartKey = `${userPrefix}${application.localStorageCartKey}`
  const localStorageFulfillmentKey = `${userPrefix}${application.localStorageFulfillmentKey}`
  const localStorageUserKey = `${userPrefix}${application.localStorageUserKey}`
  const localStorageAddressKey = `${userPrefix}${application.localStorageAddressKey}`
  const localStorageAuthTokenKey = application.localStorageAuthTokenKey

  return {
    localStorageAppBannerKey,
    localStorageCartKey,
    localStorageFulfillmentKey,
    localStorageUserKey,
    localStorageAddressKey,
    localStorageAuthTokenKey,
  }
}

// ========================================================
// save fulfillment
// ========================================================
export function* saveFulfillmentDataToLocalStorage(payload: {
  fulfillment: FulfillmentTypes
}): SagaIterator<FulfillmentTypes> {
  const { fulfillment } = payload
  const localStorageKeys: LocalStorageKeys = yield call(getLocalStorageKeys)

  const currentPageId: EntityId = yield select(
    (state: RootState) => state.application.currentPageId
  )

  const pageFulfillement = {
    [currentPageId]: fulfillment,
  }

  setLocalStorageItem(localStorageKeys.localStorageFulfillmentKey, {
    ...getLocalStorageItem(
      localStorageKeys.localStorageFulfillmentKey,
      pageFulfillement
    ),
    ...pageFulfillement,
  })

  return fulfillment
}

// ========================================================
// save checkout data
// ========================================================
export function* saveCheckoutDataToLocalStorage(): SagaIterator {
  const localStorageKeys: LocalStorageKeys = yield call(getLocalStorageKeys)
  const application: ApplicationState = yield call(getApplication)

  // GET PAGE SESSION
  const pageSession: PageSessionEntity = yield select((state: RootState) =>
    pageSessionsSelector.selectById(state, application.currentPageId)
  )

  // GET GLOBAL CART DATA
  const globalCart: CartState = yield select((state: RootState) => state.cart)

  // GET PAGE CART
  const pageCart: CartEntity = yield select((state: RootState) =>
    cartsSelector.selectById(state, pageSession.cartId)
  )

  const { user, address } = globalCart
  const { storePlace, storeId, fulfillment, storeLat, storeLng } = pageCart

  setLocalStorageItem(localStorageKeys.localStorageCartKey, {
    ...getLocalStorageItem(localStorageKeys.localStorageCartKey, {}),
    storePlace,
    storeId,
    fulfillment,
    storeLat,
    storeLng,
  })

  setLocalStorageItem(localStorageKeys.localStorageUserKey, {
    ...getLocalStorageItem(localStorageKeys.localStorageUserKey, {}),
    ...user,
  })

  setLocalStorageItem(localStorageKeys.localStorageAddressKey, {
    ...getLocalStorageItem(localStorageKeys.localStorageAddressKey, {}),
    ...address,
  })
}

// ========================================================
// restore previous checkout data
// ========================================================
export function* restoreCheckoutDataFromLocalStorage() {
  const localStorageKeys: LocalStorageKeys = yield call(getLocalStorageKeys)

  const userFromLocalStorage: User = getLocalStorageItem(
    localStorageKeys.localStorageUserKey,
    {}
  )
  const addressFromLocalStorage: Address = getLocalStorageItem(
    localStorageKeys.localStorageAddressKey,
    {}
  )

  yield put(cart.actions.updateUser(userFromLocalStorage))
  yield put(cart.actions.updateAddress(addressFromLocalStorage))
}

// ========================================================
// restore previous cart data for page
// ========================================================
export function* restoreCartDataFromLocalStorage() {
  const localStorageKeys: LocalStorageKeys = yield call(getLocalStorageKeys)
  const pages: PageEntity[] = yield call(getAllPAges)

  const cartFromLocalStorage = getLocalStorageItem(
    localStorageKeys.localStorageCartKey,
    {}
  )
  const fulfillmentFromLocalStorage = getLocalStorageItem(
    localStorageKeys.localStorageFulfillmentKey,
    ""
  )

  for (const page of pages) {
    const fulfillment: FulfillmentTypes =
      fulfillmentFromLocalStorage[page.id] ?? page.defaultFulfillment

    const currentCart: CartEntity = yield call(getCart, page.id)
    // do not force the fullfilment restore if the page has line items
    // already present in the cart
    const printServiceForFulfillment = page.printServices[fulfillment][0]
    if (currentCart.lineItemIds.length === 0 && printServiceForFulfillment) {
      let cart: Partial<CartEntity> = {
        fulfillment,
        printServiceId: printServiceForFulfillment.id,
      }

      if (cartFromLocalStorage?.storePlace) {
        cart = {
          ...cart,
          storePlace: cartFromLocalStorage?.storePlace,
          storeId: cartFromLocalStorage?.storeId,
          storeLat: cartFromLocalStorage?.storeLat,
          storeLng: cartFromLocalStorage?.storeLng,
        }
      }

      yield put(carts.actions.updateOne({ id: page.id, changes: cart }))
    }
  }
}

// ========================================================
// save auth data to local storage for future reuse
// ========================================================
export function* saveAuthDataToLocalStorage(payload: {
  token: string
}): SagaIterator {
  const localStorageKeys: LocalStorageKeys = yield call(getLocalStorageKeys)
  setLocalStorageItem(localStorageKeys.localStorageAuthTokenKey, payload.token)
}

// ========================================================
// retrieve auth data from local storage
// ========================================================
export function* loadAuthDataFromLocalStorage(): SagaIterator<string> {
  const localStorageKeys: LocalStorageKeys = yield call(getLocalStorageKeys)
  return getLocalStorageItem(localStorageKeys.localStorageAuthTokenKey, "")
}
