import { PrintServiceProductEntity } from "@jackfruit/common"
import { SagaIterator } from "@redux-saga/types"
import { PayloadAction } from "@reduxjs/toolkit"
import { call, put, select, takeEvery } from "redux-saga/effects"
import { LineItemEntity } from "~/interfaces/entities/LineItem"
import { actions, UpdateLineItemProductTemplate } from "../process"
import { lineItems, lineItemsSelectors } from "../state/lineItems"
import { printServiceProductsSelector } from "../state/printServiceProducts"
import { RootState } from "../store"
import { getProductTemplateVariantForProduct } from "./productTemplateVariants"
import {
  prepareProductPagesForVariant,
  updateProductPages,
} from "./templateHelpers"

export default function* watchUpdateLineItemProductTemplate() {
  yield takeEvery(
    actions.updateLineItemProductTemplate.type,
    processUpdateLineItemProductTemplate
  )
}

function* processUpdateLineItemProductTemplate(
  action: PayloadAction<UpdateLineItemProductTemplate>
): SagaIterator<any> {
  const { lineItemId, productTemplateId } = action.payload

  const lineItem: LineItemEntity = yield select((state: RootState) =>
    lineItemsSelectors.selectById(state, lineItemId)
  )
  const { productId } = lineItem

  const variants = yield call(getProductTemplateVariantForProduct, {
    productId,
    productTemplateId,
  })
  const [firstVariant] = variants
  const [firstPage] = firstVariant.variant.pages

  const orientation =
    firstPage.width > firstPage.height ? "landscape" : "portrait"

  // create or remove product pages based on the template variant
  const productPageIds = yield call(prepareProductPagesForVariant, {
    productPageIds: lineItem.productPageIds,
    variant: firstVariant.variant,
  })

  yield call(updateProductPages, {
    productPageIds,
    variant: firstVariant.variant,
  })

  yield put(
    lineItems.actions.updateOne({
      id: lineItemId,
      changes: {
        productPageIds,
        productTemplateId,
        productTemplateVariantId: firstVariant.id,
        orientation,
      },
    })
  )
}
