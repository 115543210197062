// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-sitemap-site-map-tsx": () => import("./../../../src/components/sitemap/SiteMap.tsx" /* webpackChunkName: "component---src-components-sitemap-site-map-tsx" */),
  "component---src-pages-account-change-password-tsx": () => import("./../../../src/pages/account/change-password.tsx" /* webpackChunkName: "component---src-pages-account-change-password-tsx" */),
  "component---src-pages-account-index-tsx": () => import("./../../../src/pages/account/index.tsx" /* webpackChunkName: "component---src-pages-account-index-tsx" */),
  "component---src-pages-account-orders-[id]-tsx": () => import("./../../../src/pages/account/orders/[id].tsx" /* webpackChunkName: "component---src-pages-account-orders-[id]-tsx" */),
  "component---src-pages-account-orders-index-tsx": () => import("./../../../src/pages/account/orders/index.tsx" /* webpackChunkName: "component---src-pages-account-orders-index-tsx" */),
  "component---src-pages-forgot-password-tsx": () => import("./../../../src/pages/forgot-password.tsx" /* webpackChunkName: "component---src-pages-forgot-password-tsx" */),
  "component---src-pages-maintenance-tsx": () => import("./../../../src/pages/maintenance.tsx" /* webpackChunkName: "component---src-pages-maintenance-tsx" */),
  "component---src-pages-register-confirm-tsx": () => import("./../../../src/pages/register/confirm.tsx" /* webpackChunkName: "component---src-pages-register-confirm-tsx" */),
  "component---src-pages-register-index-tsx": () => import("./../../../src/pages/register/index.tsx" /* webpackChunkName: "component---src-pages-register-index-tsx" */),
  "component---src-pages-reset-password-tsx": () => import("./../../../src/pages/reset-password.tsx" /* webpackChunkName: "component---src-pages-reset-password-tsx" */),
  "component---src-templates-layout-error-error-404-tsx": () => import("./../../../src/templates/layout/error/Error404.tsx" /* webpackChunkName: "component---src-templates-layout-error-error-404-tsx" */),
  "component---src-templates-layout-error-error-tsx": () => import("./../../../src/templates/layout/error/Error.tsx" /* webpackChunkName: "component---src-templates-layout-error-error-tsx" */),
  "component---src-templates-layout-page-block-page-block-layout-tsx": () => import("./../../../src/templates/layout/page-block/PageBlockLayout.tsx" /* webpackChunkName: "component---src-templates-layout-page-block-page-block-layout-tsx" */),
  "component---src-templates-layout-stores-store-tsx": () => import("./../../../src/templates/layout/stores/Store.tsx" /* webpackChunkName: "component---src-templates-layout-stores-store-tsx" */),
  "component---src-templates-layout-stores-stores-tsx": () => import("./../../../src/templates/layout/stores/Stores.tsx" /* webpackChunkName: "component---src-templates-layout-stores-stores-tsx" */)
}

