import {
  AvailableLanguages,
  ClientEntity,
  ClientPrintServiceEntity,
  EntityId,
  PrinticularOrder,
  PrintServiceEntity,
  PrintServiceProductCategoryEntity,
  PrintServiceProductCategoryImageEntity,
  PrintServiceProductEntity,
  PrintServiceProductImageEntity,
  PrintServiceProductPriceEntity,
  TemplateTextColor,
  TemplateTextFont,
} from "@jackfruit/common"
import { flatten } from "lodash"
import { ApiException } from "~/interfaces/entities/ApiException"
import { LatLng } from "~/interfaces/entities/LatLng"
import { RetailerIds } from "@jackfruit/common"
import { StoreEntity, StoreEntityV2 } from "~/interfaces/entities/Store"
import { logger } from "~/services/Logger"
import { isOrderAlreadyPlaced } from "./ApiExceptionHelper"
import { PrinticularSerializer } from "./PrinticularSerializer"
import { convertToEntities, convertToEntity } from "./Utils"
import { v4 as uuidv4 } from "uuid"

const parseError = (error: any): ApiException => {
  if (error?.errors) {
    return {
      code: error.errors[0].code,
      message: error.error?.message ?? error.errors[0].title,
      errors: error.errors,
    }
  }

  if (error.error) {
    return {
      code: error.error.code,
      message: error.error.message,
    }
  }

  if (error.message) {
    return {
      code: "Unspecified",
      message: error.message,
    }
  }

  return {
    code: "Unspecified",
    message: JSON.stringify(error),
  }
}

interface PrinticularStore {
  attributes: StoreEntityV2
  id: string
  relationships: {
    territory: {
      data: {
        id: string
        type: "Territory"
      }
    }
  }
}
interface PrinticularTerritory {
  type: "Territory"
  id: string
  attributes: {
    countryCode: string
    countryName: string
  }
}

interface StoreSearchResponse {
  data: PrinticularStore[]
  included: PrinticularTerritory[]
}

interface SimplyBluUpdateCardTokenData {
  data: {
    type: "SimplyBluUpdateCardToken"
    attributes: {
      cardToken: string
      timeZone: string
    }
    relationships: {
      printService: {
        data: {
          type: "PrintService"
          id: number
        }
      }
    }
  }
}

interface SimplyBluUpdateCardTokenResponse {
  data: {
    type: "SimplyBluUpdateCardTokenResponse"
    attributes: {
      redirectHtml: string
    }
  }
}

export class PrinticularApiV2 {
  private accessToken: string
  private deviceToken: string
  private baseUrl: string
  private headers: any
  private language: AvailableLanguages

  constructor(
    baseUrl: string,
    accessToken: string,
    deviceToken: string,
    language: AvailableLanguages = "en-US"
  ) {
    this.baseUrl = `${baseUrl}`
    this.accessToken = accessToken
    this.deviceToken = deviceToken
    this.language = language
  }

  public setDeviceToken(deviceToken: string) {
    this.deviceToken = deviceToken
  }

  public getDeviceToken() {
    return this.deviceToken
  }

  public setAccessToken(token: string) {
    this.accessToken = token
  }

  private async get(url: string) {
    try {
      this.headers = {
        Authorization: `Bearer ${this.accessToken}`,
        "Content-Type": "application/json",
        "Accept-Language": `${this.language ?? "en-US"},en;q=0.9`,
      }
      const response = await fetch(`${this.baseUrl}/${url}`, {
        headers: this.headers,
        method: "GET",
      })

      if (response.status !== 200) {
        const error = await response.json()
        const parsedError = parseError(error)

        if ([400, 401].indexOf(response.status) === -1) {
          logger.error(
            Error(`code: ${parsedError.code} message: ${parsedError.message}`)
          )
        }

        throw parsedError
      }

      const data = await response.json()

      return data
    } catch (error) {
      throw error
    }
  }

  private async post<T>(url: string, params: any) {
    try {
      this.headers = {
        Authorization: `Bearer ${this.accessToken}`,
        "Content-Type": "application/json",
        "Accept-Language": `${this.language ?? "en-US"},en;q=0.9`,
      }

      const response = await fetch(`${this.baseUrl}/${url}`, {
        headers: this.headers,
        method: "POST",
        body: JSON.stringify(params),
      })

      if ([200, 201, 204].indexOf(response.status) === -1) {
        const error = await response.json()
        const parsedError = parseError(error)

        if ([400, 401].indexOf(response.status) === -1) {
          logger.error(
            Error(`code: ${parsedError.code} message: ${parsedError.message}`)
          )
        }

        throw parsedError
      }
      const data = await response.json()

      return data as T
    } catch (error) {
      throw error
    }
  }

  public async getPrintServiceDetails(
    printServiceIds: EntityId[],
    countryCode: string
  ): Promise<{
    client: ClientEntity
    printServices: PrintServiceEntity[]
    products: PrintServiceProductEntity[]
    clientPrintServices: ClientPrintServiceEntity[]
    productImages: PrintServiceProductImageEntity[]
    productPrices: PrintServiceProductPriceEntity[]
    productCategories: PrintServiceProductCategoryEntity[]
    colors: TemplateTextColor[]
    fonts: TemplateTextFont[]
  }> {
    const response = await this.get(
      `api/v2/client?filter[countryCode]=${countryCode}&include=Client.templateTextColors,Client.templateTextFonts,Client.clientRegions,Client.clientProducts,ClientRegion.clientRegionPrintServices,ClientRegionPrintService.clientPrintService,ClientPrintService.printService,ClientProduct.product,Product.productImages,Product.prices,Product.printService,Product.category,Category.categoryImages`
    )

    const clientPrintServicesData = response.included.filter(
      (entity: any) =>
        entity.type === "ClientPrintService" &&
        printServiceIds.includes(
          Number(entity.relationships.printService.data.id)
        )
    )

    const printServicesData = response.included.filter(
      (entity: any) =>
        entity.type === "PrintService" &&
        printServiceIds.includes(Number(entity.id))
    )

    const productsData = response.included.filter(
      (entity: any) =>
        entity.type === "Product" &&
        printServiceIds.includes(
          Number(entity.relationships.printService.data.id)
        )
    )

    const productImagesData = response.included.filter(
      (entity: any) =>
        entity.type === "ProductImage" &&
        productsData
          .map((product: any) =>
            product.relationships.productImages?.data.map(
              (image: any) => image.id
            )
          )
          .flat()
          .includes(entity.id)
    )

    const productPricesData = response.included.filter(
      (entity: any) =>
        entity.type === "Price" &&
        productsData
          .map((product: any) =>
            product.relationships.prices?.data.map((price: any) => price.id)
          )
          .flat()
          .includes(entity.id)
    )

    const productCategoriesData = response.included.filter(
      (entity: any) => entity.type === "Category"
    )

    const productCategoryImagesData = response.included.filter(
      (entity: any) => entity.type === "CategoryImage"
    )

    const { templateTextColors: colors, templateTextFonts: fonts } =
      convertToEntities<{
        templateTextColors: TemplateTextColor[]
        templateTextFonts: TemplateTextFont[]
      }>(response)

    const client = convertToEntity<ClientEntity>(response.data)

    const clientPrintServices = clientPrintServicesData.map((service: any) =>
      convertToEntity<ClientPrintServiceEntity>(service)
    )

    const printServices = printServicesData.map((service: any) =>
      convertToEntity<PrintServiceEntity>(service)
    )

    const clientProductsDataForProducts = response.included
      .filter((entity: any) => entity.type === "ClientProduct")
      .reduce((acc: any, current: any) => {
        const productId = current.relationships.product.data.id
        acc[productId] = current.attributes

        return acc
      }, {})

    const products = productsData.map((product: any) => {
      product.attributes.categoryName =
        response.included.find(
          (entity: any) =>
            entity.type === "Category" &&
            product.relationships.category?.data.id === entity.id
        )?.attributes.name ?? ""

      // re apply the position based on the client product sortOrder
      product.attributes.position =
        clientProductsDataForProducts[product.id].sortOrder

      return convertToEntity<PrintServiceProductEntity>(product)
    })

    const productImages = productImagesData.map((image: any) =>
      convertToEntity<PrintServiceProductImageEntity>(image)
    )

    const productPrices = productPricesData.map((price: any) => {
      price.attributes.total = parseFloat(price.attributes.total)
      return convertToEntity<PrintServiceProductPriceEntity>(price)
    })

    const productCategories: any[] = productCategoriesData.map(
      (category: any) =>
        convertToEntity<PrintServiceProductCategoryEntity>(category)
    )

    const productCategoryImages: PrintServiceProductCategoryImageEntity[] =
      productCategoryImagesData.map((image: any) => {
        return convertToEntity<PrintServiceProductCategoryImageEntity>(image)
      })

    productCategories.forEach((category, index, collection) => {
      const images = productCategoryImages.filter(image =>
        category.categoryImages?.includes(image.id)
      )
      // fix missing sort order
      if (category.sortOrder === null) {
        category.sortOrder = 0
      }
      collection[index].images = images
    })

    return {
      client,
      printServices,
      clientPrintServices,
      products,
      productImages,
      productPrices,
      colors,
      fonts,
      productCategories,
    }
  }

  async getAvailableStores(
    latLng: LatLng,
    printServiceId: EntityId,
    printServiceProductCodes: EntityId[],
    retailerIds: RetailerIds
  ): Promise<Partial<StoreEntity>[]> {
    const productCodeList = printServiceProductCodes.sort().join(",")
    const retailers = retailerIds?.sort().join(",")
    const url = `api/v2/client/stores/search`

    if (latLng.lat === undefined || latLng.lng === undefined) {
      throw new Error("Invalid latLng")
    }

    const params: any = {
      "filter[location]": `${latLng.lat},${latLng.lng}`,
      "filter[printService]": printServiceId,
      include: "Store.territory",
    }

    if (productCodeList) {
      params["filter[products]"] = productCodeList
    }

    if (retailers) {
      params["filter[retailers]"] = retailers
    }

    const queryString = Object.keys(params)
      .sort()
      .map(key => key + "=" + params[key])
      .join("&")

    const { data, included } = (await this.get(
      `${url}?${queryString}`
    )) as StoreSearchResponse

    const territories = included?.filter(({ type }) => type === "Territory")

    return data.map(({ attributes, id, relationships: { territory } }) => {
      const foundTerritory = territories?.find(
        ({ id }) => territory.data.id === id
      )
      return {
        id,
        active: attributes.active ? 1 : 0,
        currency: attributes?.currency ?? "USD",
        latitude: `${attributes.latitude}`,
        longitude: `${attributes.longitude}`,
        name: attributes.name,
        note: attributes?.note ?? "",
        phone: attributes.phone,
        products: attributes.productCodes,
        retailerId: attributes.retailerId,
        retailerStoreId: attributes.retailerStoreId,
        address: attributes.storeAddress1,
        addressLine2: attributes?.storeAddress2 ?? "",
        city: attributes.storeCity,
        country: attributes.storeCountry,
        postcode: attributes.storePostCode,
        state: attributes.storeRegion,
        printServiceId: printServiceId as number,
        countryCode: foundTerritory?.attributes?.countryCode ?? "US",
      }
    })
  }

  /**
   *  Get list of available stores for specific coordinates and print services
   */
  async getAvailableStoresForMultiplePrintServices(
    latLng: LatLng,
    printServiceIds: EntityId[],
    printServiceProductCodes: EntityId[],
    retailerIds: RetailerIds
  ): Promise<Partial<StoreEntity>[]> {
    const results = await Promise.all(
      printServiceIds.map(async printServiceId =>
        this.getAvailableStores(
          latLng,
          printServiceId,
          printServiceProductCodes,
          retailerIds
        )
      )
    )

    return flatten(results)
  }

  async simplyBluUpdateCardToken(
    cardToken: string,
    printServiceId: number,
    timeZone: string
  ): Promise<string> {
    const data: SimplyBluUpdateCardTokenData = {
      data: {
        type: "SimplyBluUpdateCardToken",
        attributes: {
          cardToken,
          timeZone,
        },
        relationships: {
          printService: {
            data: {
              type: "PrintService",
              id: printServiceId,
            },
          },
        },
      },
    }

    const result = await this.post<SimplyBluUpdateCardTokenResponse>(
      `api/v2/client/simplyblu/update-card-token`,
      data
    )

    return result.data.attributes.redirectHtml
  }

  /**
   * Order placement API V2
   * @param payload
   * @returns PrinticularOrder
   */

  /**
   * Dry run the order on autopilot apiV2
   */
  public async dryRunOrder(payload: any): Promise<PrinticularOrder> {
    const url = `api/v2/client/orders/dryrun?include=Order.printService,Order.address,Order.giftCertificate,Order.giftCertificateTransaction,Order.lineItems,Order.store,Order.lineItems,Order.printServiceShippingMethod,LineItem.product,LineItem.productTemplate,LineItem.productTemplateVariant`

    //edit the payload for dryrun
    payload.data.attributes = {
      ...payload.data.attributes,
      nonce: uuidv4(),
    }

    const data = await this.post(url, payload)

    PrinticularSerializer.deserializeOrder(data)
    return PrinticularSerializer.deserializeOrder(data)
  }

  /**
   * Create an order on autopilot apiV2 to get the paymentIntents, stripe payment only
   */
  public async createOrder(payload: any): Promise<PrinticularOrder> {
    const url = `api/v2/client/orders/create?include=Order.printService,Order.address,Order.lineItems,Order.store,Order.lineItems,Order.printServiceShippingMethod,LineItem.product,LineItem.productTemplate,LineItem.productTemplateVariant`
    try {
      const data = await this.post(url, payload)
      return PrinticularSerializer.deserializeOrder(data)
    } catch (error: any) {
      if (isOrderAlreadyPlaced(error)) {
        const fakeOrder: any = {
          id: 1,
          lineItems: [],
          shippingMethods: [],
        }

        return fakeOrder as PrinticularOrder
      } else {
        throw error
      }
    }
  }

  /**
   * Place the order on autopilot apiV2
   */
  public async placeOrder(payload: any): Promise<PrinticularOrder> {
    const url = `api/v2/client/orders/place?include=Order.printService,Order.address,Order.lineItems,Order.store,Order.lineItems,Order.printServiceShippingMethod,LineItem.product,LineItem.productTemplate,LineItem.productTemplateVariant`

    try {
      const data = await this.post(url, payload)

      return PrinticularSerializer.deserializeOrder(data)
    } catch (error: any) {
      // in case nonce match with existing order
      // we need to fake an order for the success page
      // to display correctly
      if (isOrderAlreadyPlaced(error)) {
        const fakeOrder: any = {
          id: 1,
          lineItems: [],
          shippingMethods: [],
        }

        return fakeOrder as PrinticularOrder
      } else {
        throw error
      }
    }
  }
}
