import { Box, Flex, Icon, Link, SimpleGrid, Text } from "@chakra-ui/react"
import { AppEntityHydrated, BlockFooterEntityHydrated } from "@jackfruit/common"
import { Link as GatsbyLink } from "gatsby"
import React from "react"
import { useTranslation } from "react-i18next"
import { FaArrowRight } from "react-icons/fa"
import Wrapper from "~/components/Wrapper"
import { useSettings } from "~/hooks/useSettings"
import { ReactComponent as MeaLogo } from "~/images/MeaLogo.svg"
import HtmlContent from "../HtmlContent"
import "./FooterBlock.css"

interface Props {
  appConfig: AppEntityHydrated
  config: BlockFooterEntityHydrated
}

const FooterBlockA: React.FC<Props> = ({ appConfig, config }) => {
  const { t } = useTranslation()
  const {
    common: {
      showPoweredByAutopilotBanner,
      poweredByAutopilotBannerText,
      poweredByAutopilotRedirectUrl,
      poweredByAutopilotTextColour,
      poweredByAutopilotBg,
    },
  } = appConfig

  const {
    settings: { isSitemapEnabled, isSitemapHideFooter, sitemapLinkLabel },
  } = useSettings()

  const {
    row1Col1Html,
    row1Col2Html,
    row2Col1Html,
    row2Col2Html,
    row2Col3Html,
    row2Col4Html,
    row3Col1Html,
  } = config

  const row1Bg = config.row1Bg || "gray.100"
  const row2Bg = config.row2Bg || "gray.100"
  const row3Bg = config.row3Bg || "gray.200"

  const isSitemapLinkVisible = Boolean(isSitemapEnabled && !isSitemapHideFooter)
  const isFirstRowVisible = Boolean(
    isSitemapLinkVisible || !!row1Col1Html || !!row1Col2Html
  )

  return (
    <>
      {isFirstRowVisible && (
        <Wrapper
          bg={row1Bg}
          p={8}
          pb={0}
          className="block-footer-row block-footer-row--1"
        >
          <Flex
            justifyContent="space-between"
            alignItems="center"
            direction={["column", "column", "row"]}
            textAlign={["center", "center", "left"]}
          >
            <Box id="p-footer-sitemap-wrapper">
              {isSitemapLinkVisible && (
                <Box
                  fontSize="lg"
                  fontWeight="bold"
                  className="sitemap-link"
                  _hover={{ textDecoration: "underline" }}
                >
                  <GatsbyLink id="p-footer-sitemap-link" to="/sitemap">
                    {Boolean(sitemapLinkLabel)
                      ? sitemapLinkLabel
                      : t("components.blocks.FooterBlockA.DefaultSitemapLabel")}
                    <Icon as={FaArrowRight} boxSize={3} ml={2} />
                  </GatsbyLink>
                </Box>
              )}
              <HtmlContent html={row1Col1Html} config={appConfig} />
            </Box>

            <HtmlContent html={row1Col2Html} config={appConfig} />
          </Flex>
        </Wrapper>
      )}

      <Wrapper
        bg={row2Bg}
        pt={8}
        className="block-footer-row block-footer-row--2"
      >
        <SimpleGrid columns={{ base: 1, sm: 2, md: 4 }} spacing={4}>
          <HtmlContent html={row2Col1Html ?? ""} config={appConfig} />
          <HtmlContent html={row2Col2Html ?? ""} config={appConfig} />
          <HtmlContent html={row2Col3Html ?? ""} config={appConfig} />
          <Box>
            <HtmlContent html={row2Col4Html ?? ""} config={appConfig} />
          </Box>
        </SimpleGrid>
      </Wrapper>

      {Boolean(showPoweredByAutopilotBanner) && (
        <Wrapper
          bg={poweredByAutopilotBg ?? row3Bg}
          className="block-footer-row block-footer-row--3"
        >
          <Flex py={5} justify="center" align="center" direction="column">
            <Link href={poweredByAutopilotRedirectUrl} isExternal>
              <Flex gap={3}>
                <MeaLogo width="50px" fill={poweredByAutopilotTextColour} />
                <Text
                  fontSize="xs"
                  my="auto"
                  color={poweredByAutopilotTextColour}
                >
                  {poweredByAutopilotBannerText ||
                    t("components.blocks.FooterBlockA.PoweredByAutopilot")}
                </Text>
              </Flex>
            </Link>

            {Boolean(row3Col1Html) && (
              <Box>
                <HtmlContent html={row3Col1Html} config={appConfig} />
              </Box>
            )}
          </Flex>
        </Wrapper>
      )}
    </>
  )
}

export default FooterBlockA
