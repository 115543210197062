import { Box, Link, Icon } from "@chakra-ui/react"
import React from "react"

interface Props {
  icon: any
  link: string
  bg?: string
  color?: string
  hover?: any
  transition?: string
  size?: string
}

const SocialMediaLink: React.FC<Props> = ({
  icon,
  link,
  bg,
  color = "#fff",
  hover,
  transition = "all ease .45s",
  size = "100%",
}) => {
  return (
    <Box>
      <Link
        href={link}
        isExternal
        display="flex"
        rel="noopener"
        justifyContent="center"
        alignItems="center"
        borderRadius={50}
        color={color}
        bg={bg ?? ""}
        p={2.5}
        w={10}
        h={10}
        transition={transition}
        _hover={hover}
      >
        <Icon as={icon} w={size} h={size} />
      </Link>
    </Box>
  )
}

export default SocialMediaLink
