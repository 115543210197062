import { UseToastOptions } from "@chakra-ui/react"
import { EntityId, PageEntity } from "@jackfruit/common"
import {
  call,
  delay,
  put,
  race,
  take,
  takeEvery,
} from "@redux-saga/core/effects"
import { SagaIterator } from "@redux-saga/types"
import { PayloadAction } from "@reduxjs/toolkit"
import { Channel, channel } from "redux-saga"
import { fork } from "redux-saga/effects"
import { UPLOAD_ACCEPTED_FILE_TYPES } from "~/data/const"
import { CartEntity } from "~/interfaces/entities/Cart"
import { PageSessionEntity } from "~/interfaces/entities/PageSession"
import { logger } from "~/services/Logger"
import {
  actions,
  CreateUploadPayload,
  UploadUriImagesPayload,
} from "../process"
import { getCart } from "./cart"
import { createUpload } from "./images"
import { createLineItem, updateLineItemFromUpload } from "./lineItems"
import { getPage } from "./page"
import { attachUploadToPageSession, getPageSession } from "./pageSession"
import { t } from "i18next"

const PROCESS_WORKERS_COUNT = 4
const CREATE_UPLOAD_DELAY = 1000

export function* watchCreateUpload(): SagaIterator {
  const chan = yield call(channel)

  for (let i = 0; i < PROCESS_WORKERS_COUNT; i++) {
    yield fork(processCreateUpload, chan)
  }

  while (true) {
    const { payload } = yield take(actions.createUpload.type)

    const pageSession: PageSessionEntity = yield call(getPageSession, {
      pageSessionId: payload.pageId,
    })
    const cart: CartEntity = yield call(getCart, pageSession.cartId)

    const lineItemId = yield call(createLineItem)

    yield put(actions.addLineItemToCart({ lineItemId, cartId: cart.id }))

    yield put(chan, { ...payload, lineItemId })
  }
}

function* processCreateUpload(
  chan: Channel<CreateUploadPayload & { lineItemId: EntityId }>
): SagaIterator {
  while (true) {
    const payload = yield take(chan)
    yield delay(CREATE_UPLOAD_DELAY)
    const { pageId, file, scrollTo, nextBlockName, lineItemId } = payload
    try {
      // create upload
      const { uploadId } = yield call(createUpload, { file })
      yield call(attachUploadToPageSession, uploadId)

      // queue upload for processing
      yield put(
        actions.queueImageForProcessing({
          id: uploadId,
          file: file,
        })
      )

      if (scrollTo && nextBlockName) {
        scrollTo(nextBlockName)
      }

      yield call(applyFlowForUpload, { pageId, uploadId, lineItemId })
    } catch (error) {
      logger.error(error)
    }
  }
}

export function* applyFlowForUpload(payload: {
  pageId: EntityId
  uploadId: EntityId
  lineItemId: EntityId
}): SagaIterator<EntityId> {
  const { pageId, uploadId, lineItemId } = payload
  const page: PageEntity = yield call(getPage, { pageId })
  const pageSession: PageSessionEntity = yield call(getPageSession, {
    pageSessionId: pageId,
  })
  const cart: CartEntity = yield call(getCart, pageSession.cartId)

  // store first pickup has special needs
  if (
    cart.fulfillment === "pickup" &&
    pageSession.pageFlow === "store-first" &&
    !pageSession.hasLoadedStores
  ) {
    // pause until a store has been selected
    // or fulfillment has been changed
    yield race([
      take(actions.updatedStoreForPage(page.id).type),
      take(actions.updatedFulfillmentForPage(page.id).type),
    ])
  }

  yield call(updateLineItemFromUpload, {
    lineItemId,
    uploadId,
  })

  yield put(
    actions.updateOrderSummary({
      reason: "created line item after upload",
      reasonType: "cartChange",
    })
  )

  return lineItemId
}

export function* watchUploadUriImages(): SagaIterator {
  yield takeEvery(actions.uploadUriImages.type, processUploadUriImages)
}

function* processUploadUriImages(
  action: PayloadAction<UploadUriImagesPayload>
): SagaIterator {
  const { imageURIs, pageId, toast, scrollTo, nextBlockName } = action.payload

  if (imageURIs.length === 0) {
    return
  }

  const imageURIsWithCount = new Map<string, number>()
  imageURIs.forEach(uri => {
    imageURIsWithCount.set(uri, (imageURIsWithCount.get(uri) ?? 0) + 1)
  })

  let uploadErrorCount = 0

  for (const [imageUri, count] of imageURIsWithCount) {
    try {
      const response = yield call(fetch, imageUri)
      const contentType = response.headers.get("content-type")
      if (contentType && UPLOAD_ACCEPTED_FILE_TYPES.includes(contentType)) {
        const blob = yield call([response, "blob"])
        const file = new File([blob], imageUri, { type: contentType })

        for (let i = 0; i < count; i++) {
          yield put(actions.createUpload({ pageId, file }))
        }
      } else {
        throw new Error(`Invalid file type: ${contentType}`)
      }
    } catch (error: any) {
      uploadErrorCount++
    }
  }

  if (imageURIs.length !== uploadErrorCount && scrollTo && nextBlockName) {
    scrollTo(nextBlockName)
  }

  // Show error popup if there are any errors
  if (uploadErrorCount > 0) {
    const title = t(
      "redux.saga.processUploads.processUploadUriImages.ErrorTitle",
      { count: uploadErrorCount }
    )
    const description = t(
      "redux.saga.processUploads.processUploadUriImages.ErrorDescription",
      { count: uploadErrorCount }
    )

    const toastOptions: UseToastOptions = {
      status: "error",
      duration: 3000,
      isClosable: true,
      title,
      description,
    }

    toast(toastOptions)
  }
}
