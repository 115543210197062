import { replace } from "@wordpress/shortcode"
import React from "react"
import { renderToString } from "react-dom/server"
import Navigation from "../../components/short-codes/Navigation"
import { ShortCodeParser, ShortCodeStateEntities } from "../replaceShortCodes"

export const parser: ShortCodeParser = (
  html: string = "",
  state: ShortCodeStateEntities
) => {
  return replace("navigation", html, args => {
    const id = parseInt(args.attrs.named.id || "0")
    const additionalClasses = args.attrs.named.class?.split(" ") || []
    const appConfig = state.appConfig!
    const navigation = appConfig.navigations.find(nav => +nav.id === id)

    if (!navigation) {
      return `[navigation error="not found #${id}"]`
    }

    const rendered = renderToString(
      <Navigation
        navigation={navigation}
        pages={appConfig.pages}
        extraClasses={additionalClasses}
      />
    )

    return rendered
  })
}
