import { AvailableLanguages, SettingsEntityHydrated } from "@jackfruit/common"
import { select } from "@redux-saga/core/effects"
import { SagaIterator } from "@redux-saga/types"
import { PrinticularApi } from "~/services/PrinticularApi"
import { createPrinticularApi } from "~/services/PrinticularApiProxy"
import { PrinticularApiV2 } from "~/services/PrinticularApiV2"
import { AuthState } from "../state/auth"
import { RootState } from "../store"

export function* getApi(): SagaIterator<PrinticularApi> {
  const settings: SettingsEntityHydrated = yield select(
    (state: RootState) => state.settings.data
  )
  const language: AvailableLanguages = yield select(
    (state: RootState) => state.application.language
  )
  const authState: AuthState = yield select((state: RootState) => state.auth)
  const { remoteApiUrl, remoteApiClientApiKey, deviceToken } = settings
  let authToken = remoteApiClientApiKey
  if (authState.isAuthenticated) {
    authToken = authState.loginToken
  }

  const printicularApi = createPrinticularApi(
    remoteApiUrl,
    authToken,
    deviceToken,
    language
  )

  return printicularApi
}

export function* getApiV2(): SagaIterator<PrinticularApiV2> {
  const settings: SettingsEntityHydrated = yield select(
    (state: RootState) => state.settings.data
  )
  const language: AvailableLanguages = yield select(
    (state: RootState) => state.application.language
  )
  const authState: AuthState = yield select((state: RootState) => state.auth)
  const { remoteApiUrl, remoteApiClientApiKey, deviceToken } = settings

  let authToken = remoteApiClientApiKey
  if (authState.isAuthenticated) {
    authToken = authState.loginToken
  }

  const printicularApi = new PrinticularApiV2(
    remoteApiUrl,
    authToken,
    deviceToken,
    language
  )

  return printicularApi
}
