import { Flex, Icon, Link, Menu, MenuButton, MenuList } from "@chakra-ui/react"
import React from "react"
import { useTranslation } from "react-i18next"
import { FaUserCircle } from "react-icons/fa"
import { RiArrowDownSFill, RiArrowUpSFill } from "react-icons/ri"
import { useSettings } from "~/hooks/useSettings"
import SignInForm from "./SignInForm"

const SignInMenu = ({ color = "inherit" }: { color?: string }) => {
  const { t } = useTranslation()
  const {
    settings: { isUserAccountEnabled },
  } = useSettings()

  if (!isUserAccountEnabled) {
    return null
  }

  return (
    <Menu placement="bottom-end" closeOnSelect={false}>
      {({ isOpen }) => (
        <>
          <MenuButton
            name="p-header-sign-in"
            className="p-header-sign-in"
            as={Link}
            display="flex"
            alignItems="stretch"
            color={color}
            _active={{ textDecoration: "underline" }}
          >
            <Flex
              display="flex"
              alignItems="center"
              fontSize="xs"
              height="100%"
            >
              <Icon as={FaUserCircle} mr={2} boxSize={5} />{" "}
              {t("components.header.SignIn.SignInMenu.MyAccount")}
              <Icon
                as={isOpen ? RiArrowUpSFill : RiArrowDownSFill}
                ml={1}
                boxSize={4}
              />
            </Flex>
          </MenuButton>
          <MenuList zIndex={999} boxShadow="md" width="400px">
            <SignInForm />
          </MenuList>
        </>
      )}
    </Menu>
  )
}

export default SignInMenu
