import { Flex, StackProps } from "@chakra-ui/react"
import {
  NavigationLinkEntityHydrated,
  PageEntityHydrated,
} from "@jackfruit/common"
import arrayToTree from "array-to-tree"
import React, { useMemo } from "react"
import { addPagePathToNavigationLink } from "~/services/Utils"
import NavigationLink from "./NavigationLink"

interface Props extends StackProps {
  color: string
  colorHover?: string
  fontSize?: string
  navigation: { links: NavigationLinkEntityHydrated[] }
  pages: PageEntityHydrated[]
}

const Navigation: React.FC<Props> = ({
  color,
  colorHover,
  fontSize = "md",
  navigation,
  pages,
  ...rest
}) => {
  const navigationTree = useMemo(() => {
    const orderedLinks = addPagePathToNavigationLink(navigation.links, pages)
    const tree = arrayToTree(orderedLinks, {
      parentProperty: "parentId",
    })
    return tree
  }, [navigation.links, pages])

  const {
    justify = "center",
    flexWrap = "wrap",
    mr = "0",
    ...navigationLinkProps
  } = rest

  return (
    <Flex
      direction="row"
      justify={justify}
      flexWrap={flexWrap}
      align="center"
      mr={mr}
    >
      {navigationTree.map((link, index) => {
        return (
          <NavigationLink
            color={color}
            colorHover={colorHover}
            fontSize={fontSize}
            key={link.id}
            link={link}
            {...navigationLinkProps}
          />
        )
      })}
    </Flex>
  )
}

export default Navigation
