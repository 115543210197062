import {
  Box,
  Button,
  Icon,
  Link as ChakraLink,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from "@chakra-ui/react"
import { NavigationLinkEntityHydrated } from "@jackfruit/common"
import { useLocation } from "@reach/router"
import { Link as GatsbyLink } from "gatsby"
import React, { useMemo } from "react"
import { FiChevronDown } from "react-icons/fi"
import HtmlAddon from "./HtmlAddon"
import MegaMenu from "./megamenu/MegaMenu"

interface NavigationLinkProps {
  color: string
  colorHover?: string
  fontSize: string
  link: NavigationLinkEntityHydrated
  isSubMenu?: boolean
}

const NavigationLink: React.FC<NavigationLinkProps> = ({
  link,
  color,
  colorHover,
  fontSize,
  isSubMenu = false,
  ...rest
}) => {
  const {
    type,
    path,
    label,
    labelPrefixHtml,
    labelSuffixHtml,
    external,
    children,
    page,
    megamenu,
  } = link
  const fontWeight = 400

  const { search } = useLocation()
  const referralCode = useMemo(() => {
    const param = new URLSearchParams(search).get("referralCode")
    return Boolean(param) ? `?referralCode=${param}` : ""
  }, [search])

  switch (type) {
    case "link":
      return (
        <NavigationButton
          color={color}
          colorHover={colorHover}
          fontSize={fontSize}
          isExternal={external}
          path={path ?? "/"}
          isSubMenu={isSubMenu}
          {...rest}
        >
          <HtmlAddon placement={"left"} html={labelPrefixHtml}></HtmlAddon>
          {label}
          <HtmlAddon placement={"right"} html={labelSuffixHtml}></HtmlAddon>
        </NavigationButton>
      )
    case "page":
      return (
        <NavigationButton
          color={color}
          colorHover={colorHover}
          fontSize={fontSize}
          isExternal={false}
          path={`/${page.path}${referralCode}`}
          isSubMenu={isSubMenu}
          {...rest}
        >
          <HtmlAddon placement={"left"} html={labelPrefixHtml}></HtmlAddon>
          {label}
          <HtmlAddon placement={"right"} html={labelSuffixHtml}></HtmlAddon>
        </NavigationButton>
      )
    case "text":
      return (
        <Menu gutter={8}>
          <MenuButton
            as={Button}
            variant="link"
            fontWeight={fontWeight}
            padding={4}
            borderRadius={0}
            lineHeight="shorter"
            color={color}
            _hover={{ textDecoration: "underline", color: colorHover ?? color }}
            rightIcon={<Icon as={FiChevronDown} />}
          >
            <HtmlAddon placement={"left"} html={labelPrefixHtml}></HtmlAddon>
            {label}
            <HtmlAddon placement={"right"} html={labelSuffixHtml}></HtmlAddon>
          </MenuButton>
          {children && (
            <MenuList py={0} boxShadow="base">
              {children.map((child, index) => {
                const isFirst = index === 0
                const isLast = index === children.length - 1
                return (
                  <MenuItem
                    key={child.id}
                    padding={0}
                    borderTopRadius={isFirst ? 5 : 0}
                    borderBottomRadius={isLast ? 5 : 0}
                  >
                    <NavigationLink
                      color={color}
                      colorHover={colorHover}
                      link={child}
                      fontSize={fontSize}
                      isSubMenu={true}
                      {...rest}
                    />
                  </MenuItem>
                )
              })}
            </MenuList>
          )}
        </Menu>
      )
    case "megamenu":
      return (
        <MegaMenu
          megamenu={megamenu}
          label={label}
          color={color}
          colorHover={colorHover}
        />
      )
  }
}

interface NavigationButtonProps {
  children: React.ReactNode
  color: string
  colorHover?: string
  fontSize: string
  path: string
  isExternal: boolean
  isSubMenu?: boolean
}

interface NavigationButtonLinkProps {
  activeStyle?: { color?: string; textDecoration?: string }
  isExternal?: boolean
  rel?: string
  onClick?: () => void
}

const NavigationButton: React.FC<NavigationButtonProps> = ({
  color,
  colorHover,
  fontSize,
  children,
  path,
  isExternal,
  isSubMenu = false,
  ...rest
}) => {
  // const theme = useTheme() // @Todo handle after cleaning this component up with better props
  const linkProps: NavigationButtonLinkProps = {}

  if (isExternal) {
    linkProps.isExternal = true
    linkProps.rel = "noopener"
    linkProps.onClick = () => window.open(path, "newWindow")
  } else {
    linkProps.activeStyle = {
      // color: theme.colors.primary["500"], // @Todo handle after cleaning this component up with better props
      textDecoration: "underline",
    }
  }

  const { px = 4, py, ...navigationBoxProps } = rest as any

  return (
    <Box
      as={isExternal ? ChakraLink : GatsbyLink}
      justifyContent="flex-start"
      fontWeight="normal"
      textAlign={isSubMenu ? "left" : "center"}
      lineHeight="shorter"
      to={path}
      fontSize={fontSize}
      color={color}
      px={px}
      py={isSubMenu ? 2 : py}
      width={isSubMenu ? "full" : "auto"}
      _hover={{ textDecoration: "underline", color: colorHover ?? color }}
      {...linkProps}
      {...navigationBoxProps}
    >
      {children}
    </Box>
  )
}

export default NavigationLink
