import { TemplateTextFont } from "@jackfruit/common"
import React, { useMemo } from "react"
import { ViewBox } from "~/interfaces/editors/ViewBox"
import { TextRegionEntity } from "~/interfaces/entities/TextRegion"
import { Point } from "~/services/cropHelpers"
import { sanitizeTemplate } from "~/services/SvgUtils"
import { StaticTextRegionPreview } from "./TextRegionPreview"

export interface Image {
  externalUrl: string
  width: number
  height: number
  rotation: number
  translation: Point
  zoom: number
}

export interface ImageRegion {
  window: ViewBox
  image: Image
}

export interface TextRegion {
  window: ViewBox
  align: string
  color: string
  font: string
  text: string
  size: number
  width: number
}

export interface RendererPage {
  svg?: string
  viewBox: ViewBox
  imageRegions: ImageRegion[]
  textRegions: TextRegion[]
  fonts: TemplateTextFont[]
}

interface Props {
  page: RendererPage
  forEditorDisplay?: boolean
}

const ProductPageRenderer: React.FC<Props> = ({
  page,
  forEditorDisplay = true,
}) => {
  const sanitizedSvg = useMemo(() => {
    return sanitizeTemplate(page.svg ?? "")
  }, [page.svg])

  const svg = forEditorDisplay ? sanitizedSvg : page.svg ?? ""

  return (
    <svg
      viewBox={`0 0 ${page.viewBox.width} ${page.viewBox.height}`}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <defs>
        <style>
          {page.fonts
            .map(
              ({ ttf, woff2 }) => `
            @font-face {
              font-family: '${ttf.fontFamily}';
              font-style: ${ttf.fontStyle};
              font-weight: ${ttf.fontWeight};
              src: url(${ttf.src}) format('ttf');
            }
            @font-face {
              font-family: '${woff2.fontFamily}';
              font-style: ${woff2.fontStyle};
              font-weight: ${woff2.fontWeight};
              src: url(${woff2.src}) format('woff2');
              unicode-range: ${woff2.unicodeRange.join(" ")};
            }
          `
            )
            .join("")}
        </style>
      </defs>
      {page.imageRegions.map((imageRegion, i) => {
        const { window, image } = imageRegion
        const { externalUrl, width, height, rotation, translation, zoom } =
          image
        const { x, y } = translation
        const cx = width / 2
        const cy = height / 2
        const r = rotation
        const z = zoom
        return (
          <svg
            key={i}
            x={window.x}
            y={window.y}
            width={window.width}
            height={window.height}
            viewBox={`${0} ${0} ${window.width} ${window.height}`}
          >
            <image
              style={{
                transformOrigin: `${cx}px ${cy}px`,
                transform: `translate(${x}px, ${y}px) scale(${z}) rotate(${r}deg)`,
                transformBox: `fill-box`,
              }}
              xlinkHref={externalUrl}
            />
          </svg>
        )
      })}
      {page.svg && (
        <g
          width="100%"
          height="100%"
          style={{ pointerEvents: "none" }}
          dangerouslySetInnerHTML={{ __html: svg }}
        ></g>
      )}
      {page.textRegions.map((textRegion, i) => {
        const textRegionEntity = textRegion as TextRegionEntity
        return <StaticTextRegionPreview key={i} textRegion={textRegionEntity} />
      })}
    </svg>
  )
}

export default ProductPageRenderer
